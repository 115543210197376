import loadGmaps from 'load-google-maps-api';

export default {
	init: function () {
		if(document.querySelector('#contact-map') !== null) {
			this.initMap(document.querySelector('#contact-map'));
		}
	},
	initMap: function(el) {
		if(typeof el.dataset.lat === 'undefined' && typeof el.dataset.lng === 'undefined')
			return true;

		loadGmaps({key: 'AIzaSyAc4qeSw9vL5jXPnI12LbPKI7KnoZm5Sa4'}).then(function (googleMaps) {
			el.map = new googleMaps.Map(el, {
				center: {
					lat: parseFloat(el.dataset.lat),
					lng: parseFloat(el.dataset.lng)
				},
				disableDefaultUI: true,
				styles: [
					{
						"featureType": "administrative.province",
						"elementType": "all",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "landscape",
						"elementType": "all",
						"stylers": [
							{
								"visibility": "on"
							},
							{
								"color": "#e9edef"
							}
						]
					},
					{
						"featureType": "poi",
						"elementType": "all",
						"stylers": [
							{
								"saturation": -100
							},
							{
								"lightness": 51
							},
							{
								"visibility": "off"
							},
							{
								"color": "#dee2e4"
							}
						]
					},
					{
						"featureType": "poi.business",
						"elementType": "labels.icon",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "road",
						"elementType": "labels",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "road.highway",
						"elementType": "all",
						"stylers": [
							{
								"saturation": -100
							},
							{
								"visibility": "simplified"
							}
						]
					},
					{
						"featureType": "road.highway",
						"elementType": "labels",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "road.arterial",
						"elementType": "all",
						"stylers": [
							{
								"saturation": -100
							},
							{
								"lightness": 30
							},
							{
								"visibility": "on"
							}
						]
					},
					{
						"featureType": "road.local",
						"elementType": "all",
						"stylers": [
							{
								"saturation": -100
							},
							{
								"lightness": 40
							},
							{
								"visibility": "on"
							}
						]
					},
					{
						"featureType": "transit",
						"elementType": "all",
						"stylers": [
							{
								"saturation": -100
							},
							{
								"visibility": "simplified"
							}
						]
					},
					{
						"featureType": "transit",
						"elementType": "labels",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "water",
						"elementType": "geometry",
						"stylers": [
							{
								"lightness": -25
							},
							{
								"saturation": -97
							},
							{
								"color": "#7c81a3"
							}
						]
					},
					{
						"featureType": "water",
						"elementType": "labels",
						"stylers": [
							{
								"visibility": "off"
							},
							{
								"lightness": -25
							},
							{
								"saturation": -100
							}
						]
					}
				],
				zoom: 15
			});

			el.marker = new googleMaps.Marker({
				position: {
					lat: parseFloat(el.dataset.lat),
					lng: parseFloat(el.dataset.lng)
				},
				map: el.map,
				icon: {
					url :        '/wp-content/themes/class/dist/images/map-marker.png',
					size :       new googleMaps.Size(100, 124),
					scaledSize : new googleMaps.Size(50, 62),
					origin :     new googleMaps.Point(0, 0),
					anchor :     new googleMaps.Point(25, 62)
				}
			});

		}).catch(function (error) {
			console.error(error)
		})
	}
};
