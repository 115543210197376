export default class Nav {
	constructor(element) {
		if(!(element instanceof HTMLElement)) {
			throw new TypeError('Invalid element');
		}

		this.nav = element;
		this.offset = document.querySelector('#page-header').offsetHeight;

		window.addEventListener('scroll', ev => {
			if(window.pageYOffset > this.offset){
				this.nav.classList.add('affixed');
			} else {
				this.nav.classList.remove('affixed');
			}
		}, { passive: true });
	}
};