/* globals jQuery */
import "../sass/theme.scss";

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit.js';
import GLightbox from 'glightbox';
import MicroModal from 'micromodal';
import Choices from 'choices.js';

import Tabs from './modules/tabs.js';
import Map from './modules/map.js';
import Nav from './modules/nav.js';
import {fetchSVG} from "./lib/SVGSprite.js";

window.MicroModal = MicroModal;

const bootstrap = {
	init: function() {
		if(typeof NodeList.prototype.forEach !== 'function'){
			NodeList.prototype.forEach = Array.prototype.forEach;
		}

		Tabs.init();
		Map.init();

		if(document.getElementById('page-nav') !== null){
			let nav = new Nav(document.getElementById('page-nav'));
		}

		let lightbox = GLightbox({ selector: '.lightbox' });


		window.MicroModal.init({
			disableScroll: false,
			awaitCloseAnimation: false,
			debugMode: true,
		});

		jQuery(document).on('cf.form.submit', function (event, data) {
			let modal = data.$form[0].closest('.modal');

			if(modal !== null){
				MicroModal.show(modal.id);
				MicroModal.close();
			}
		});

		if(document.querySelector('.nav-trigger') !== null){
			document.querySelectorAll('.nav-trigger').forEach( el => {
				el.addEventListener('click', ev => {
					ev.preventDefault();

					document.body.classList.toggle('nav-open');
				});
			});
		}

		const choices = new Choices('.styled-select', {
			searchEnabled: false,
		});

		//Allow user to interrupt auto-scrolling
		$(document).bind('scroll mousedown wheel keyup', function(e) {
			if(e.which || e.type === 'mousedown' || e.type === 'mousewheel') {
				$(document.scrollingElement).stop();
			}
		});
	},
  asyncSVG: {
    init() {
      fetchSVG("./wp-content/themes/class/dist/sprite.svg")
        .catch((error) => console.warn(`[fetchSVG] ${error}`));
    },
  },
	//Prefer creating new modules when appropriate, rather than adding functions here
	//If you need something to be globally accessible, you can assign an imported module to this namespace
	//I.e import Contact from './contact';
	//    window.namespace.Contact = Contact;
};

document.addEventListener("DOMContentLoaded", () => {
	bootstrap.init();
});

