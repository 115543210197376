let grid;

export default {
	init: function(){
		if(document.querySelector('#products-grid') !== null && document.querySelector('#product-tags') !== null){
			grid = document.querySelector('#products-grid');
			document.querySelector('#product-tags').addEventListener('change', this.changeTag);
		}
	},
	changeTag: function(ev){
		let tag = ev.target.value;

		if(!tag.length) {
			grid.querySelectorAll('[class^="col-"]').forEach(el => {
				el.style.display = 'inline-block';
			});
		} else {
			grid.querySelectorAll('[class^="col-"]').forEach(el => {
				el.style.display = 'none';
			});

			grid.querySelectorAll('.page-link').forEach(el => {
				let types = el.dataset.tags.split('|');

				if(types.includes(tag)){
					el.parentNode.style.display = 'inline-block';
				}
			});
		}
	}
};